export const config = {
    paths : {
        frontUrl: 'https://staging.lighthub.law/',
        apiBaseUrl: 'https://staging.lighthub.law/api/v1/',
        serverPublicUrl: 'https://staging.lighthub.law/api/public/', 
    },
    keys:{
        azureClient : '37ffc04b-9eff-4b70-95dc-a86deec94590',
        cexClient : '83664782997466',
        stripePK : 'pk_test_MyOpxkDPMMuiFZkGCqT42M4O'
    }
}